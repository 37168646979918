import React from "react";

export default ({ color = "white", width = "66", height = "66" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.70726 0.292893C1.31673 -0.0976311 0.683567 -0.0976311 0.293043 0.292893C-0.0974816 0.683418 -0.0974816 1.31658 0.293043 1.70711L27.5859 29L0.292893 56.293C-0.0976308 56.6835 -0.0976312 57.3167 0.292893 57.7072C0.683417 58.0977 1.31658 58.0977 1.70711 57.7072L29.0001 30.4142L56.293 57.7071C56.6836 58.0976 57.3167 58.0976 57.7073 57.7071C58.0978 57.3166 58.0978 56.6834 57.7073 56.2929L30.4143 29L57.7071 1.7072C58.0976 1.31668 58.0976 0.683511 57.7071 0.292986C57.3166 -0.097538 56.6834 -0.097538 56.2929 0.292986L29.0001 27.5858L1.70726 0.292893Z"
        fill={color}
      />
    </svg>
  );
};
